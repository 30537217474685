<template>
  <section id="top">
    <div
      class="banner"
      :style="{ backgroundImage: 'url(' + img_url + 'banner_loja.jpg' + ')' }"
    >
      <h2>{{ content.store.banner.title }}</h2>
      <div class="filters">
        <v-row class="search-input">
          <div class="search-container">
            <span class="icomoon icon-icon-search"></span>
            <v-text-field
              v-model="searchTxt"
              :label="content.search.search"
              dark
            ></v-text-field>
          </div>
        </v-row>

        <v-select
          dark
          :items="regions"
          item-text="text"
          item-value="value"
          :label="content.store.banner.filter1"
          v-model="regionTxt"
          outlined
        ></v-select>
        <!-- <v-select
          dark
          :items="sub_regions"
          item-text="text"
          item-value="value"
          :label="content.store.banner.filter5"
          v-model="subRegionTxt"
          outlined
        ></v-select> -->
        <v-select
          dark
          :items="brands"
          item-text="text"
          item-value="value"
          :label="content.store.banner.filter2"
          v-model="brand"
          outlined
        ></v-select>
        <v-select
          dark
          :items="types"
          item-text="text"
          item-value="value"
          :label="content.store.banner.filter3"
          v-model="type"
          outlined
        ></v-select>
        <v-select
          dark
          :items="years"
          item-text="text"
          item-value="value"
          :label="content.store.banner.filter4"
          v-model="year"
          outlined
        ></v-select>
        <v-select
          dark
          :items="order"
          item-text="text"
          item-value="value"
          label="Order By"
          v-model="orderBy"
          outlined
        ></v-select>
      </div>
    </div>
    <div class="wines-container">
      <!-- +1024px -->
      <show-at
        breakpoint="large"
        :breakpoints="{ small: 768, medium: 1024, large: 3000 }"
        class="large"
      >
        <!-- >= 3 elementos -->
        <div v-if="vinhos.length >= 3" class="elem3">
          <!-- resto 0 -->
          <div v-if="vinhos.length % 3 == 0" class="rest0">
            <WinesList
              @update-cart="updateCart()"
              :wines="vinhos"
              :itemPerRow="3"
            />
          </div>

          <!-- resto 1 -->
          <div v-else-if="vinhos.length % 3 == 1" class="rest1">
            <WinesList
              @update-cart="updateCart()"
              :wines="vinhos"
              :itemPerRow="3"
            />
          </div>

          <!--resto 2 -->
          <div v-else class="rest2">
            <WinesList
              @update-cart="updateCart()"
              :wines="vinhos"
              :itemPerRow="3"
            />
          </div>
        </div>

        <!-- =2 elementos -->
        <div v-else-if="vinhos.length == 2" class="elem2">
          <div>
            <WinesList
              @update-cart="updateCart()"
              :wines="vinhos"
              :itemPerRow="2"
            />
          </div>
        </div>

        <!-- =1 elemento -->
        <div v-else-if="vinhos.length == 1" class="elem1">
          <div>
            <WinesList
              @update-cart="updateCart()"
              :wines="vinhos"
              :itemPerRow="1"
            />
          </div>
        </div>
      </show-at>

      <!--900px a 768px-->
      <show-at
        breakpoint="medium"
        :breakpoints="{ small: 768, medium: 1024, large: 3000 }"
        class="medium"
      >
        <!-- >= 2 elementos -->
        <div v-if="vinhos.length >= 2" class="elem2">
          <!-- resto 0 -->
          <div v-if="vinhos.length % 2 == 0" class="rest0">
            <WinesList
              @update-cart="updateCart()"
              :wines="vinhos"
              :itemPerRow="2"
            />
          </div>

          <!--resto 1 -->
          <div v-if="vinhos.length % 2 == 1" class="rest0">
            <WinesList
              @update-cart="updateCart()"
              :wines="vinhos"
              :itemPerRow="2"
            />
          </div>
        </div>
        <!-- =1 elemento -->
        <div v-else class="elem1">
          <div>
            <WinesList
              @update-cart="updateCart()"
              :wines="vinhos"
              :itemPerRow="1"
            />
          </div>
        </div>
      </show-at>

      <!-- -768px -->
      <show-at
        breakpoint="small"
        :breakpoints="{ small: 768, medium: 1024, large: 3000 }"
      >
        <div class="small">
          <div>
            <div>
              <WinesList
                @update-cart="updateCart()"
                :wines="vinhos"
                :itemPerRow="1"
              />
            </div>
          </div>
        </div>
      </show-at>
    </div>
  </section>
</template>

<script>
import computedMixins from "@/mixins/computed";
import methodsMixins from "@/mixins/methods";
import WinesList from "@/components/Wines-list";
import { showAt } from "vue-breakpoints";

export default {
  mixins: [computedMixins, methodsMixins],

  components: {
    WinesList,
    showAt,
  },

  data() {
    return {
      region: "All",
      sub_region: "All",
      brand: "All",
      type: "All",
      year: "All",
      items: [],
      search: "",
      // initializeEP: "products/list-all",
      order:[
        {text: "All", value:"All"},
        {text: "A to Z", value:"az"},
        {text: "Z to A", value:"za"},
        {text: "Lower Price", value:"hp"},
        {text: "Higher Price", value:"lp"},
        {text: "Lower Year", value:"ly"},
        {text: "Higher Year", value:"hy"}
      ],
      orderBy: "All",
      n: 0
    };
  },
  created() {
    this.initialize(this.initializeEP);
    this.search = this.$route.params.search || "";
    this.region = this.$route.params.region || "All";
  },
  computed: {
    initializeEP() {
      let temp = "products/"+this.version+"list-all"
      return temp
    },
    searchTxt: {
      get() {
        let temp = this.search == "-" ? "" : this.search
        return temp
      },
      set(val) {
        this.search = val
      }
    },
    regionTxt: {
      get() {
        let temp = this.region == "All" ? "All" : this.region.toLowerCase()

        return temp
      },
      set(val) {
        this.region = val
      }
    },
    subRegionTxt: {
      get() {
        let temp = this.sub_region == "All" ? "All" : this.sub_region.toLowerCase()

        return temp
      },
      set(val) {
        this.sub_region = val
      }
    },
    vinhos() {
      let temp = this.dados;
      let app = this;

      temp = this.dados.filter((e) => {
        if (
          this.search != null &&
          this.search != "" &&
          this.search.length > 3
        ) {
          let q_s = this.search.split(" ");
          for (let i in q_s) {
            q_s[i] = q_s[i].normalize("NFD").replace(/[\u0300-\u036f]/g, "");
            if (e.name.toLowerCase().includes(q_s[i])) {
              return true;
            } else if (e.description.toLowerCase().includes(q_s[i])) {
              return true;
            } else {
              // for(let tag of e.tags) {
              //     tag = tag.normalize('NFD').replace(/[\u0300-\u036f]/g, "")
              //     let q_s = this.search.split(" ")
              //     for(let i in q_s) {
              //         if(q_s[i].length > 2) {
              //             q_s[i] = q_s[i].normalize('NFD').replace(/[\u0300-\u036f]/g, "")
              //             if(tag.toLowerCase().includes(q_s[i])) {
              //                 return true
              //             }
              //         }
              //     }
              // }

              return false;
            }
          }
        } else {
          return true;
        }
      });

      if (this.region != "All") {
        temp = [...new Set(temp.filter((item) => item.master_region.toLowerCase().trim() == app.region.toLowerCase().trim()))];
      }

      if (this.sub_region != "All") {
        temp = [...new Set(temp.filter((item) => item.sub_region.toLowerCase().trim() == app.sub_region.toLowerCase().trim()))];
      }

      if (this.brand != "All") {
        temp = [...new Set(temp.filter((item) => item.winery.toLowerCase().trim() == app.brand.toLowerCase().trim()))];
      }

      if (this.type != "All") {
        temp = [...new Set(temp.filter((item) => item.type.toLowerCase().trim() == app.type.toLowerCase().trim()))];
      }

      if (this.year != "All") {
        temp = [...new Set(temp.filter((item) => item.year.toLowerCase().trim() == app.year.toLowerCase().trim()))];
      }

      if(this.orderBy != "All") {
        if(this.orderBy == "az") {
          temp = temp.sort((a, b) => (a.name > b.name ? 1 : -1))
        }
        else if(this.orderBy == "za") {
          temp = temp.sort((a, b) => (a.name < b.name ? 1 : -1))
        }
        else if(this.orderBy == "hp") {
          temp = temp.sort((a, b) => (a.price > b.price ? 1 : -1))
        }
        else if(this.orderBy == "lp") {
          temp = temp.sort((a, b) => (a.price < b.price ? 1 : -1))
        }
        else if(this.orderBy == "ly") {
          temp = temp.sort((a, b) => (a.year > b.year ? 1 : -1))
        }
        else if(this.orderBy == "hy") {
          temp = temp.sort((a, b) => (a.year < b.year ? 1 : -1))
        }
      }

      return temp;
    },
    types() {
      let temp = [...new Set(this.vinhos.map((item) => {
          let value = item.type.toLowerCase()
          let text = item.type

          return {text, value}
        }
      ))];

      temp.sort((a, b) => (a.text.normalize("NFD").replace(/[\u0300-\u036f]/g, "") > b.text.normalize("NFD").replace(/[\u0300-\u036f]/g, "")) ? 1 : -1)

      temp.unshift("All");

      return temp;
    },
    years() {
      let temp = [...new Set(this.vinhos.map((item) => {
          let value = item.year.toLowerCase().trim()
          let text = item.year.trim()

          return {text, value}
        }
      ))];

      temp.sort((a, b) => (a.text.normalize("NFD").replace(/[\u0300-\u036f]/g, "") > b.text.normalize("NFD").replace(/[\u0300-\u036f]/g, "")) ? 1 : -1)

      temp.unshift("All");

      return temp;
    },
    regions() {
      let temp = [...new Set(this.vinhos.map((item) => {
          let value = item.master_region.toLowerCase().trim()
          let text = item.master_region.trim()

          return {text, value}
        }
      ))];

      temp.sort((a, b) => (a.text.normalize("NFD").replace(/[\u0300-\u036f]/g, "") > b.text.normalize("NFD").replace(/[\u0300-\u036f]/g, "")) ? 1 : -1)

      temp.unshift("All");

      return temp;
    },
    sub_regions() {
      let temp = [...new Set(this.vinhos.map((item) => {
          let value = item.sub_region.toLowerCase().trim()
          let text = item.sub_region.trim()

          return {text, value}
        }
      ))];

      temp.sort((a, b) => (a.text.normalize("NFD").replace(/[\u0300-\u036f]/g, "") > b.text.normalize("NFD").replace(/[\u0300-\u036f]/g, "")) ? 1 : -1)

      temp.unshift("All");

      return temp;
    },
    brands() {
      let temp = [...new Set(this.vinhos.map((item) => {
          let value = item.winery.toLowerCase().trim()
          let text = item.winery.trim()

          return {text, value}
        }
      ))];

      temp.sort((a, b) => (a.text.normalize("NFD").replace(/[\u0300-\u036f]/g, "") > b.text.normalize("NFD").replace(/[\u0300-\u036f]/g, "")) ? 1 : -1)
      
      temp.unshift("All");

      return temp;
    },
    materiaisCarrinho() {
      this.n;
      let temp = JSON.parse(window.localStorage.getItem("cart")) || [];

      return temp;
    },
  },
  methods: {
    async updateCart() {
      this.n += 1
      this.$emit("update-cart");
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/style.scss";

section {
  .banner {
    text-align: center;
    padding: 150px 20px;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    @include responsive(0, 1024px) {
      padding: 80px 20px;
    }
    @include responsive(0, 512px) {
      padding: 60px 10px;
      padding-top: 120px;
    }
    h2{
      margin-bottom: 15px;
    }
    .filters {
      margin-top: 0px;
      .v-input {
        display: inline-block;
        width: 15%;
        margin: 0.5%;
        @include responsive(0, 1024px) {
          width: 40%;
          margin: 0 5px;
        }
        @include responsive(0, 512px) {
          width: 80%;
          margin: 0 5px;
        }
        .v-input__slot {
          // border: 1px solid white !important;
          border-radius: 0 !important;
          margin-bottom: 0;
        }
        .theme--dark.v-text-field--outlined:not(.v-input--is-focused):not(.v-input--has-state),
        .v-input__control,
        .v-input__slot,
        fieldset {
          color: rgba(255, 255, 255, 1) !important;
          border-width: 1px;
        }
      }
    }
  }

  .wines-container {
    div {
      div {
        .rows {
          .row {
            .wines {
              margin: 0 auto !important;
              width: 100%;
              .wine {
                display: inline-block;
                vertical-align: top;
                height: 100%;
                .card {
                  @include responsive(0, 1700px) {
                    text-align: center;
                  }
                  .wine-preview {
                    width: 400px;
                    text-align: center;
                    margin: 50px auto;
                    @include responsive(0, 1700px) {
                      width: 280px;
                    }
                    .image-container {
                      .hover {
                        right: 100px;
                        @include responsive(0, 1700px) {
                          right: 40px;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      &.large {
        &.elem3 {
          .wines {
            .wine {
              width: calc(50% - 200px);
              @include responsive(0, 1700px) {
                width: 33.3%;
              }
            }
            .wine:nth-child(1) {
              text-align: right;
              @include responsive(0, 1700px) {
                text-align: center;
              }
            }
            .wine:nth-child(2) {
              width: 400px;
              @include responsive(0, 1700px) {
                width: 33.3%;
              }
            }
          }
          .row:nth-child(even) {
            .wine:nth-child(1) {
              background-color: $light-greyII;
            }
            .wine:nth-child(2) {
              background-color: $light-greyI;
            }
            .wine:nth-child(3) {
              background-color: $light-greyIII;
            }
          }
          .row:nth-child(odd) {
            .wine:nth-child(1) {
              background-color: $light-greyIII;
            }
            .wine:nth-child(2) {
              background-color: $light-greyII;
            }
            .wine:nth-child(3) {
              background-color: $light-greyI;
            }
          }
          // .rest1 {
          //   .row:nth-last-child(1){
          //     .wine {
          //       width: 100%;
          //     }
          //   }
          // }
          // .rest2 {
          //   .row:nth-last-child(1){
          //     .wine {
          //       width: 50%;
          //     }
          //   }
          // }
        }
        &.elem2 {
          .wines {
            .wine {
              width: 50%;
              @include responsive(0, 1700px) {
                width: 50%;
              }
            }
            .wine:nth-child(1) {
              text-align: right;
            }
          }
          .wine:nth-child(1) {
            background-color: $light-greyIII;
          }
          .wine:nth-child(2) {
            background-color: $light-greyI;
          }
        }
        &.elem1 {
          text-align: center;
          .wines {
            .wine {
              width: 100%;
              background-color: $light-greyII;
            }
          }
        }
      }
      &.medium {
        &.elem2 {
          .wines {
            .wine {
              width: 50%;
              @include responsive(0, 1700px) {
                width: 50%;
              }
            }
            .wine:nth-child(1) {
              text-align: right;
            }
          }
          .row:nth-child(even) {
            .wine:nth-child(1) {
              background-color: $light-greyII;
            }
            .wine:nth-child(2) {
              background-color: $light-greyI;
            }
          }
          .row:nth-child(odd) {
            .wine:nth-child(1) {
              background-color: $light-greyIII;
            }
            .wine:nth-child(2) {
              background-color: $light-greyII;
            }
          }
        }
        &.elem1 {
          text-align: center;
          .wines {
            .wine {
              width: 100%;
              background-color: $light-greyII;
            }
          }
        }
      }
      &.small {
        text-align: center;
        .wines {
          .wine {
            width: 100%;
            background-color: $light-greyII;
          }
        }
        .row:nth-child(3n) {
          .wine:nth-child(1) {
            background-color: $light-greyII;
          }
        }
        .row:nth-child(3n + 1) {
          .wine:nth-child(1) {
            background-color: $light-greyIII;
          }
        }
        .row:nth-child(3n + 2) {
          .wine:nth-child(1) {
            background-color: $light-greyI;
          }
        }
      }
    }
  }
}

.search-input {
  position: relative;
  width: 100% !important;
  text-align: center;
  margin: 0 auto;
  margin-bottom: 20px;

  .v-label {
    left: 30px !important;
  }
  input {
    padding-left: 30px;
  }
  .search-container{
    position: relative;
    width: 300px!important;
    margin: 0 auto;
    @include responsive(0, 512px){
      width: 280px!important;
    }

  }
  .icomoon {
    color: white;
    font-size: 16px;
    margin-right: 10px;
    position: absolute;
    top: 20px;
    left: calc(50% - 140px);
    @include responsive(0, 1024px){
      top: 15px;
    }
    @include responsive(0, 512px){
      left: calc(50% - 130px);
    }
  }
  .v-input {
    width: 100%!important;
    @include responsive(0, 512px){
      margin-bottom: 20px!important;
    }
  }
}

.row{
  margin-right: 0px;
    margin-left: 0px;
}
</style>
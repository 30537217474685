<template>
  <ul class="rows">
    <li v-for="(row, i) in Math.ceil(wines.length / itemPerRow)" :key="i" class="row">
      <ul class="wines">
        <li
          v-for="(wine, j) in wines.slice(i * itemPerRow, (i + 1) * itemPerRow)"
          :key="j"
          class="wine"
        >
          <div class="card">
            <ProductPreview :wine="wine" @update-cart="updateCart()" />
          </div>
        </li>
      </ul>
    </li>
  </ul>
</template>

<script>
import computedMixins from "@/mixins/computed";
import methodsMixins from "@/mixins/methods";
import ProductPreview from "@/components/Product-preview";

export default {
  mixins: [computedMixins, methodsMixins],
  props: ["wines", "itemPerRow"],
  components: {
    ProductPreview,
  },
  methods: {
    async updateCart() {
      this.$emit("update-cart")
    }
  }
};
</script>